body.outro {
    .content {
      position: relative;
      min-height: 100%;
        header {
          padding: 1.25rem 1.25rem 29px;
            .outro_header {
                text-align: center;
                margin: auto;
                font-size: 40px;

                @include breakpoint-up('sm') {
                  font-size: 64px;
                }

                @include breakpoint-up('md') {
                    max-width: 590px;
                    text-align: left;
                }
            }
        }

        .outro-form_wrapper {
          width: 100%;
          padding-top: 25px;
          max-width: 300px;
          margin: 0 auto;
          flex-direction: column;
          justify-content: space-between;


          @include breakpoint-up('sm') {
            max-width: 500px;
            padding-top: 45px;
          }
          @include breakpoint-up('lg') {
            max-width: 590px;
            padding-top: 50px;


          }
            .outro-form_container {
              padding-bottom: 25px;
              @include breakpoint-up('sm') {
                padding-bottom: 45px;
              }
              @include breakpoint-up('lg') {
                padding-bottom: 50px;
              }
                .outro-form_header {
                    padding-bottom: 35px;

                    @include breakpoint-up('sm') {
                      padding-bottom: 75px;
                    }
                    @include breakpoint-up('md') {
                      padding-bottom: 35px;
                    }
                    p {
                      font-size: 1rem;
                      line-height: 1.25rem;

                      @include breakpoint-up('sm') {
                        font-size: 1.125rem;
                        line-height: 1.5rem;
                      }
                    }
                }

                .outro-form {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .outro-form_buttons {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        width: 100%;
                        margin-top: 55px;

                        @include breakpoint-up('sm') {
                            flex-direction: row;
                            justify-content: normal;
                        }
                        @include breakpoint-up('md') {
                          width: 90%;
                        }
                        button, a {
                            margin-bottom: 1.25rem;
                            cursor: pointer;
                        }

                        .download-cta {
                          margin-left: 1rem !important;
                        }

                        a {
                            background-color: #000000;
                            border-color: #ffffff;
                            color: #ffffff;
                            margin-left: 1rem;

                            &:hover {
                                background-color: #ffffff;
                                border-color: #000000;
                                color: #000000;
                            }
                        }
                    }


                }


            }
            .outro-form_footer {
                text-align: center;
                z-index: 1;
                p {
                  font-size: 12px;
                }
            }
            input{
                outline: 0px;
                border: 0px;
                border-bottom: 1px solid #ffffff;
                border-radius: 0;
                width: 100%;
                line-height: 1;
                width: 310px;
                font-size: 14px;
                padding: 10px 5px;
                -webkit-appearance: none;
                @include breakpoint-up('sm') {
                    width: 470px;
                    font-size: 18px;

                }
            }
            input:focus-visible, input:focus, input:focus-within {
                outline: 0px;
                border: 0px;
                border-bottom: 1px solid #ffffff;
                border-radius: 0;
                width: 100%;
                line-height: 1;
                width: 310px;
                font-size: 14px;
                padding: 10px 5px;
                -webkit-appearance: none;
                @include breakpoint-up('sm') {
                    width: 470px;
                    font-size: 18px;

                }
            }
        }

        .outro_actions {
            &-header {
                display: flex;
                padding-left: 0;
                @include breakpoint-up('sm') {
                    display: none;
                }

                .q-button-go-back {
                    display: flex;
                    z-index: 1;
                    @include breakpoint-up('sm') {
                        display: none;
                    }
                }
            }

            &-footer {
                display: none;
                @include breakpoint-up('sm') {
                    display: flex;
                    z-index: 1;
                    position: absolute;
                    padding: 0 0 0 1.25rem;
                    bottom: 0;
                    left: 0;
                }

                .q-button-go-back {
                    display: none;
                    padding: 0px;

                    @include breakpoint-up('sm') {
                        display: flex;
                        z-index: 1;
                    }
                }
            }
        }
    }
}


body.outro.facilitated{
    .content{
        .outro-form_wrapper{
            .outro-form_container{
                .outro-form{
                    input#name{
                        ::placeholder{
                            color: #fff;
                            opacity: 0.8;
                        }
                    }

                    .outro-form_buttons{
                        display: block;
                        @include breakpoint-up('sm') {
                            display: flex;
                        }
                        width: auto;
                        button, a{
                            margin: 0 22px 20px 0 !important;
                            display: inline-block !important;
                            min-width: 0;
                            padding: rem(19) rem(43);
                        }
                    }
                }
            }
            .outro_actions-footer {
                display: none;
            }
        }
    }

}

.middle-east-north-africa{
    &.outro {
      .content {
        header {
            .outro_header {
                @include breakpoint-up('md') {
                    text-align: right;
                }
            }
        }
      }
    }
  }