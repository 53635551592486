// Styles and layout for the survey intro page

.start {
	background-color: color('ui-purple');

	.content {
		padding: 2rem 1.25rem;

		@include breakpoint-up('sm') {
			padding: 1.25rem 2rem 1.5rem;
		}
	}

	.content-inner {
        width:100%;
		padding: 0 0rem 0 0;
        max-width: 900px;
        margin: 0 auto;
	}
}

//LAC title styling
.es-MX, .es-CO, .es-DO {
		.start-title {
			h1 {
				font-size: 2rem;

				@include breakpoint-up('sm') {
					font-size: 2.5rem;
				}
			}
		}
}

.start-title {
	margin-top: 2.25rem;

	@include breakpoint-up('sm') {
		margin-top: 2.5rem;
	}

	h1 {
		font-size: 2.5rem;
		line-height: 3rem;

		@include breakpoint-up('sm') {
			font-size: 4rem;
			line-height: 4rem;
		}
	}
}

.start-text {
	margin-top: 1.66rem;

	@include breakpoint-up('sm') {
		margin-top: 1.66rem;
	}

	p {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
}

.start-form {
    width:100%;
    padding: 0 0rem 0 0;
    max-width: 900px;
    margin: 0.5rem auto 0;

	@include breakpoint-up('sm') {
	    margin: 1.66rem auto 0;
	}

    .chosen-container .chosen-single span{
        font-size: rem(18);
    }

	.form-actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;

		@include breakpoint-up('sm') {
			margin-top: 4.5rem;
		}
	}
}

.middle-east-north-africa{
	.start-form {
		.field-group > * {
			margin-left: 2.5rem;
		}

		.form-actions {
			justify-content: flex-start;
		}
	}
}