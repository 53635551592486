// Styles for the Questions template

.q-header {
	background-color: color('ui-purple');
	background-image: url('../../img/texture-bokeh.png');
	background-size: cover;
	background-position: center center;
	padding: 1.25rem 1.25rem 1rem;

    @include breakpoint-up('sm') {
		padding-bottom: 4rem;
    }

    .q-header-inner{
        @include breakpoint-up('sm') {
            padding: 0rem 0rem 0rem;
        }

        width: 100%;
        max-width: 900px;
        margin: 0 auto;

        h1{
            max-width: 800px;
        	margin-top: 0;

			@include breakpoint-up('sm') {
	        	margin-top: 0.675rem;
			}
        }
    }
}

body.india-bengali, body.india-marathi, body.india-telugu, body.india-kannada{
	.q-header h1 {
		@include ffMalden('light');
	}
}

.q-question {
	font-size: 1.125rem;
    line-height: 1.75rem;
    display: none;

    &.is-active{
        display: block;
    }

	@include breakpoint-up('sm') {
		font-size: 2.1rem;
		line-height: 2.75rem;
	}
}

.q-answers {
    padding: 2rem 0 1rem 0;
    display: none;

	@include breakpoint-up('sm') {
		padding: 0 0 4rem 0;
	}

	&.q-answers-4 {

		@include breakpoint-up('sm') {
			padding: 0;
		}

		.field-with-label {
			flex: none;
			width: 100%;

			@include breakpoint-up('md') {
				flex: 0 1 auto;
				width: auto;
			}

			textarea {
				width: 100%;

				@include breakpoint-up('md') {
					width: 33.5rem;
				}
			}
		}
	}

    &.is-active{
        display: flex;
    }

	&.textarea-group {
		padding: 2rem 1.25rem 1rem;

		@include breakpoint-up('sm') {
			padding: 2rem 1.25rem 1.25rem;
		}

		> * {
			flex: 1;

			@include breakpoint-up('sm') {
				flex: 0 1 auto;
			}
		}
	}
}

.q-actions {
	padding: 1.25rem;

	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column-reverse;

		@include breakpoint-up('xs') {
			padding: 0 0 4rem 0;
			margin-top: 4.062rem;
			max-width: 900px;
	    	margin: 0 auto;
			flex-direction: row;
		}
	}

	> button,
	> .cta {
		min-width: 0;
	}
}

.q-button-goto-stories,
.q-button-next-question,
.q-button-go-back,
.q-button-prev-question {
    display: none;

    &.is-active{
        display: block;
    }
}

.q-button-go-back, .q-button-prev-question {
	text-decoration: none;

	&::before {
		display: inline-block;
		content: '';
		width: 7px;
		height: 0.875rem;
		background-image: url('/images/icon-toggle-right-white.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 0.875rem;
		margin-right: 0.875rem;
		transform: rotate(180deg);
	}

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&.is-active {
		display: flex;
		align-items: center;
	}
}


.questions.facilitated{
    .q-actions{
        .q-actions-inner{
            flex-direction: row;
        }
    }
}

.middle-east-north-africa{
	.q-header .q-header-inner h1 {
		max-width: 800px;
		margin-left: auto;
	}
}