.facilitated-error{
    header{
        padding: 1.25rem 1.25rem 29px;
        .error_header{
            font-size: 40px;
            @include breakpoint-up('sm') {
                font-size: 64px;
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
            }

            @include breakpoint-up('lg') {
                max-width: 900px;
            }
        }
    }
    .facilitator-expiry-error-main {
        width: 100%;
        padding-top: 25px;
        max-width: 300px;
        margin: 0 auto;
        flex-direction: column;
        justify-content: space-between;


        @include breakpoint-up('sm') {
            max-width: 500px;
            padding-top: 45px;
        }
        @include breakpoint-up('lg') {
            max-width: 900px;
            padding-top: 50px;


        }
        .cta{
            margin-top: 40px;
        }
    }
}

