// Styles for the About modal

.about-content {
	background-color: color('ui-white');
	color: color('ui-black');
	padding: 2.25rem 1.25rem 5.625rem;

	@include breakpoint-up('sm') {
		padding: 1.25rem 1.5rem 8.5rem;
	}

	&.is-modal {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		overflow-Y: scroll;
	}

	&.is-hidden {
		display: none;
	}
}


.about-content-inner {
	max-width: 40rem;

	@include breakpoint-up('sm') {
		margin: 0 auto 0 15%;
	}

	.is-modal & {
		padding-bottom: 5.625rem;

		@include breakpoint-up('sm') {
			padding-bottom: 8.5rem;
		}
	}
}

.about-content {
    .imported-logo {
        filter: brightness(0) saturate(100%);
    }

    .site-logo {
		a {
			color: color('ui-black');
		}

		path {
			fill: color('ui-black');
		}
	}

	h1, h2, h3 {
		color: color('ui-purple');
	}

	h1 {
		font-size: 2.5rem;
		line-height: 3.125rem;
		letter-spacing: 0.005em;
		margin-top: 2.875rem;
	}

	h2:not(.site-logo) {
		@include ffMalden('bold');
		font-size: 1.5rem;
		line-height: 2.25rem;
		margin-top: 3.5rem;

		@include breakpoint-up('sm') {
			font-size: 2rem;
		}
	}

	p {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}

	p + p {
		margin-top: 1.5rem;
	}

	h1 + p {
		margin-top: 2rem;
	}

	h2 + p {
		margin-top: 1rem;
	}
}

.middle-east-north-africa{
	.about-content-inner {
	
		@include breakpoint-up('sm') {
			margin: 0 15% 0 auto;
		}
	}
}

