.tooltip {
    visibility: hidden;
    width: rem(208);
    background-color: color('ui-black');
    border: color('ui-white') solid 1px;
    color: #fff;
    text-align: center;
    padding: rem(10) rem(16);
    @include ffMalden('bold');
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.05em;
    height:rem(40);
    border-radius: rem(20);
    
    position: absolute;
    z-index: 1;
    
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: color('ui-white') transparent transparent transparent;
    }

    &.show{
        visibility: visible;
        opacity: 1;
    }
}
