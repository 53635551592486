// Styles and layout for the survey intro page

.session {
	background-color: color('ui-purple');


	.content {
		padding: 2rem 1.25rem;
        position: relative;
		@include breakpoint-up('sm') {
			padding: 1.25rem 2rem 1.5rem;
		}
	}

	.content-inner {
        width:100%;
		padding: 0 0rem 0 0;
        max-width: 900px;
        margin: 0 auto;

	}
}

.session-title {
	margin-top: 2.25rem;

	@include breakpoint-up('sm') {
		margin-top: 6rem;
	}

	h1 {
        font-size: rem(24);
        line-height: rem(32);

        @include breakpoint-up('sm') {
            font-size: rem(40);
            font-size: rem(40);
        }
    }
}

.session-text {
	margin-top: rem(26);
    margin-bottom: rem(26);

    @include breakpoint-up('sm') {
        margin-top: rem(24);
        margin-bottom: rem(29);
    }

    p {
        @include ffMalden('bold');
        font-size: rem(18);
        line-height: rem(24);

        @include breakpoint-up('sm') {
            font-size: rem(24);
            line-height: rem(35);
            letter-spacing: 0.12px;
        }
    }
}

.session-info, .session-stats, .session-control-text{
    span{
        display: block;
        font-size: rem(18);
        letter-spacing: 0px;
        line-height: rem(28);
        margin-bottom: 0;

        @include breakpoint-up('sm') {
            font-size: rem(24);
            letter-spacing: 0.12px;
            line-height: rem(35);
            margin-bottom: rem(3);
        }
    }
    margin-bottom: rem(26);

    @include breakpoint-up('sm') {
        margin-bottom: rem(29);
    }
}

.session-urls{
    .session-url{
        margin-bottom: rem(26);

        @include breakpoint-up('sm') {
            margin-bottom: rem(29);
        }
    }
    label{
        display: block;
        font-size: rem(18);
        letter-spacing: 0px;
        line-height: rem(28);
        margin-bottom: rem(16);
        @include ffMalden('bold');

        @include breakpoint-up('sm') {
            font-size: rem(24);
            letter-spacing: 0.12px;
            line-height: rem(35);
            margin-bottom: rem(16);
        }
    }

    .session-input-wrapper{
        position: relative;
        width: 100%;
        @include breakpoint-up('sm') {
            width: fit-content;
        }
    }
    input {
        border: 1px solid color('ui-white');
        border-radius: rem(3) 0 0 rem(3);
        font-size: rem(18);
        line-height: rem(24);
        padding: rem(8) rem(12) rem(10) rem(12);
        height:rem(44);
        cursor:pointer;
        max-width: calc(100% - 45px);
        width: calc(100% - 45px);
        text-overflow: ellipsis;

        @include breakpoint-up('sm') {
            min-width: rem(508);
            width: auto;
        }
    }
    button, button:hover, button:active, button:focus {
        height:rem(44);
        width: rem(45);
        min-width:0;
        background-color: transparent !important;
        position: absolute;
        top:0;
        right:0;
        border-radius: 0 rem(3) rem(3) 0;
        max-width:rem(45);
        padding:0;
        border-left: 0;
        cursor:pointer;
        background-image: url('/images/icon-copy.svg') !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px 17px;

        @include breakpoint-up('sm') {
            right:-45px;
        }


    }
}

.session-stats {
    margin-bottom: rem(52);
    @include breakpoint-up('sm') {
        margin-bottom: rem(58);
    }
}

.session-controls {
    display: block;

    margin-bottom: rem(52);

    @include breakpoint-up('sm') {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(80);
    }

    .session-control-text{
        margin-bottom: rem(26);

        @include breakpoint-up('sm') {
            margin-bottom: 0;
        }
        span{
            line-height: 24px;
        }
    }

    button#download_session_data{
        min-height:rem(56);
        padding: rem(19) rem(32);
        border-radius: 2.5rem;
        @include breakpoint-up('sm') {
            padding: rem(20) rem(66);
        }
    }
}

.middle-east-north-africa{
    .session-urls input{  
        margin-right: rem(45);
        
    }
    .session-urls button, .session-urls button:hover, .session-urls button:active, .session-urls button:focus{
        right: 0px;
    }

}
