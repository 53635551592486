.wrong-locale-header {
	background-color: color('ui-purple');
	background-image: url('../../img/texture-bokeh.png');
	background-size: cover;
	background-position: center center;
	padding: rem(20) rem(20) rem(24);

    @include breakpoint-down('sm') {
        img{
            height: rem(32) !important;
        }
    }

    @include breakpoint-up('sm') {
		padding-bottom: rem(30);
    }

    .wrong-locale-header-inner{
        @include breakpoint-up('sm') {
            padding: 0rem 0rem 0rem;
        }

        width: 100%;
        max-width: rem(445);
        margin: 0 auto;

        h1{
            max-width: rem(445);
        	margin-top: rem(114);
            font-size: rem(30);
            line-height: rem(32);

			@include breakpoint-up('sm') {
                margin-top: 0;
	        	margin-top: rem(116);
                font-size: rem(40);
                line-height: rem(48);
			}
        }
    }
}

.wrong-locale-content{
    padding: rem(30) rem(20) rem(0);

    width: 100%;
    max-width: rem(445);
    margin: 0 auto;

    @include breakpoint-up('sm') {
        padding: rem(30) rem(0) rem(0);
    }

    .wrong-locale-sub-header, .wrong-locale-text{
        font-size: rem(14);
        line-height: rem(20);

        @include breakpoint-up('sm') {
            font-size: rem(18);
            line-height: rem(32);
        }
        strong {
            @include ffMalden('bold');
        
        }
    }

}

.wrong-locale-actions {
    padding: rem(32) rem(20) rem(20);

    @include breakpoint-up('sm') {
	    padding: rem(40) rem(20) rem(20);
    }

	&-inner {
		display: flex;
		flex-direction: column;

		@include breakpoint-up('xs') {
			padding: 0 0 4rem 0;
			max-width: rem(445);
	    	margin: 0 auto;
		}
        @include breakpoint-up('sm') {
            flex-direction: row;
            justify-content: normal;
        }


        > button,
        > .cta {
            min-width: 0;
            max-width: fit-content;
        }
    }
}


.wrong-locale-cta {
    margin-bottom: 1.25rem;
    @include breakpoint-up('sm') {
        margin-bottom: 0;
        margin-right: 1rem;
    }
}

.wrong-locale{
    .support-cta {
        background-color: #000000;
        border-color: #ffffff;
        color: #ffffff;

        &:hover {
            background-color: #ffffff;
            border-color: #000000;
            color: #000000;
        }
    }
    .site-footer{
        display: none;
    }
}
