// Styles for the Support template

.support {
    background-color: color("ui-black");

    .page-intro {
        background-color: color("ui-purple");
        background-image: url("../../img/texture-bokeh.png");
        background-size: cover;
        background-position: center center;
    }

    .site-footer {
        background-color: color("ui-purple");

        #dg_footer_wrapper {
            background-color: color("ui-purple");
        }
    }
}

@keyframes nowrap {
    from {
        white-space: nowrap;
        overflow: hidden;
    }
    99% {
        white-space: nowrap;
        overflow: hidden;
    }
    to {
        white-space: normal;
        overflow: auto;
    }
}

.support-accordion {
    background-color: color("ui-white");
    color: color("ui-black");

    @include breakpoint-up("md") {
        background-image: url("/images/ui-long-arrow-down.svg");
        background-repeat: no-repeat;
        background-position: 4rem calc(100% - 5.375rem);
        background-size: 12px 115px;
        min-height: 35rem;
        padding-left: 8.625rem;
    }

    .accordion-segment {
        border-width: 0 0 2px 0;
        border-style: solid;
        border-color: color("ui-grey");
        overflow: hidden;

        @include breakpoint-up("md") {
            flex: 0 0 auto;
            border-width: 0 0 0 2px;
            border-image: linear-gradient(
                    180deg,
                    color("ui-purple"),
                    color("ui-black")
                )
                2;
            position: relative;
        }
    }
    .accordion-segment.is-active {
        @include breakpoint-up("md") {
            padding: 0 28% 0 0;
        }
    }

    .accordion-tab {
        display: flex;
        align-items: center;
        padding: 1.75rem 1.25rem;

        @include breakpoint-up("md") {
            display: block;
            padding: 1rem;
            transition: padding 0.5s;
        }
    }
    .is-active .accordion-tab {
        @include breakpoint-up("md") {
            padding: 1rem 2rem;
        }
    }

    .accordion-content {
        display: flex;
        flex-direction: column;
        padding: 0 1.25rem;

        @include breakpoint-up("md") {
            display: block;
            padding: 0 1.75rem 4.5rem 1.75rem;
        }
    }
    .is-active .accordion-content {
        @include breakpoint-up("md") {
            padding: 1.25rem 2rem;
        }
    }

    .accordion-tab-number {
        font-size: 1.125rem;
        line-height: 2.25rem;
    }
    .is-active .accordion-tab-number {
        @include ffMalden("bold");
    }

    .accordion-tab-title {
        @include ffMalden("regular");
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin: 0 0 0 auto;
        transition: all 0.5s;
        text-align: right;
        width: 70%;

        @include breakpoint-up("md") {
            font-size: 1.5rem;
            line-height: 2.25rem;
            margin: 2.5rem 0 0 0;

            &::after {
                content: "";
                display: inline-block;
                width: 9px;
                height: 16px;
                margin-left: 1rem;
                background-image: url("/images/icon-toggle-right-black.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: auto 1rem;
                transform: rotate(180deg);
            }
        }
    }
    .is-active .accordion-tab-title {
        @include ffMalden("bold");

        @include breakpoint-up("md") {
            @include ffMalden("regular");
            font-size: 2rem;
            line-height: 2.5rem;
            max-height: 5rem;
            overflow: hidden;

            &::after {
                transform: rotate(0);
            }
        }
    }

    .accordion-tab-toggle {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        @include breakpoint-up("md") {
            display: none;
        }

        path {
            fill: color("ui-black");
        }
    }
    .is-active .accordion-tab-toggle {
        transform: rotate(180deg);
    }

    .accordion-detail {
        padding: 1.25rem 0;
        opacity: 0;

        @include breakpoint-up("md") {
            max-height: 0;
            padding: 0;
            overflow: hidden;
        }
    }
    .is-active .accordion-detail {
        opacity: 1;
        animation: fadein 1s linear 1;

        @include breakpoint-up("md") {
            max-height: none;
        }
    }

    .accordion-content-image {
        order: -1;

        @include breakpoint-up("md") {
            display: none;
            align-items: center;
            background-color: color("ui-light-grey");
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: left 0.5s;
        }
    }
    .is-active .accordion-content-image {
        display: flex;
        justify-content: center;

        @include breakpoint-up("md") {
            left: 50%;
        }
    }

    .accordion-content-blurb {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    .accordion-content-link {
        display: block;
        @include ffMalden("bold");
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding-left: 1.75rem;
        margin-top: 2rem;
        background-image: url("/images/icon-external_link.svg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 1rem 1rem;
    }

    &[data-slide-count="1"] {
        @include breakpoint-up("md") {
            > .accordion-segment {
                width: 0;

                &.is-active {
                    width: 100%;
                    padding: 0 50% 0 0;
                }
            }
        }
    }

    &[data-slide-count="2"] {
        @include breakpoint-up("md") {
            > .accordion-segment {
                width: 30%;

                &.is-active {
                    width: 70%;
                    padding: 0 35% 0 0;
                }
            }
        }
    }
}

//styling to thailand accordion
.th-TH {
    .support-accordion {
        @include breakpoint-up("md") {
            height: 80vh;
        }

        .accordion-tab-title {
            margin: 0;
        }
        .accordion-content-blurb {
            font-size: 1rem;
        }
    }
}
// Story grid styles
.support {
    .story-grid {
        display: block;
        padding: 3.5rem 0 4rem 0;
        background-position: right 133%;

        @include breakpoint-up("sm") {
            background-position: 130% 0;
        }

        .sub-heading {
            padding: 1rem 0 4rem 3rem;
        }

        .story-card.is-primary {
            margin-bottom: 0;

            .story-card-content {
                padding: 5rem 1.25rem 1rem 8.75rem;

                @include breakpoint-up("sm") {
                    padding: 9.5rem 1.25rem 1rem 22rem;
                }
            }

            .story-card-actions {
                margin-top: 2.5rem;

                .story-card-cta {
                    margin-top: 0;
                }
                .cta {
                    padding: 0.875rem 1.75rem;
                }
            }
        }
    }
}

.middle-east-north-africa {
    &.support {
        .support-accordion .accordion-tab-title {
            @include breakpoint-up("md") {
                margin: 0 0 0 0;
                width: 100%;
            }
        }
        .story-grid {
            display: grid;
        }

        .sub-heading {
            padding-right: 1.25rem;
        }
    }
}
