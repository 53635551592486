// Styles and layout for the survey intro page

.name {
	background-color: color('ui-purple');

	.content {
		padding: 2rem 1.25rem;

		@include breakpoint-up('sm') {
			padding: 1.25rem 2rem 1.5rem;
		}
	}

	.content-inner {
        width:100%;
		padding: 0 0rem 0 0;
        max-width: 900px;
        margin: 0 auto;
	}
}

.name-title {
	margin-top: 2.25rem;

	@include breakpoint-up('sm') {
		margin-top: 6rem;
	}

	h1 {
        font-size: rem(24);
        line-height: rem(32);

        @include breakpoint-up('sm') {
            font-size: rem(40);
            font-size: rem(40);
        }
    }
}

.name-text {
	margin-top: rem(26);

    @include breakpoint-up('sm') {
        margin-top: rem(24);
    }

    p {
        font-size: rem(18);
        line-height: rem(24);

        @include breakpoint-up('sm') {
            font-size: rem(24);
            line-height: rem(35);
            letter-spacing: 0.12px;
        }
    }
}

.name-form {
    width:100%;
    max-width: 900px;
    margin: rem(20) auto 0;

	@include breakpoint-up('sm') {
		padding: 0;
	}

	.form-actions {
		display: flex;
		justify-content: flex-start;
		margin-top: rem(26);

		@include breakpoint-up('sm') {
			margin-top: rem(45);
		}
	}

    input.facilitator-name{
        font-size: rem(14);
        border: none;
        border-bottom: 1px solid #fff;
        border-radius: 0;
        padding-left: rem(4);
        &:focus-visible{
            outline: none;
        }
        &::placeholder{
            color: #fff;
            opacity: 0.8;
        }

        @include breakpoint-up('sm') {
			font-size: rem(18);
		}
    }
}
