.thanks-next{
    header{
       @include split-header;
    }
    .thanks-next-main{
       @include dark-body-section;
        .text-container{
            max-width: 480px;
        }
        .cta{
            display: table;
            margin-left: auto;
            margin-top: 40px;
        }
    }
}

