// Styles for the Story template

.story-content {
    position: relative;

    &.no-after:after{
        display: none;
    }

    .video-bg:after{
        display: none;
    }

    .video-bg{
        z-index:1;
    }

}

.video-js-box{
    display: none;

    &.is-active{
        display: block;
    }

    .vjs-volume-panel {
        flex: none !important;
        transition: none !important;
        width: 9.375rem !important;

        &:active,
        &:focus,
        &:hover {
            width: 9.375rem !important;

            .vjs-volume-control {
                &.vjs-volume-horizontal {
                    margin-left: auto;
                }
            }
        }

        .vjs-volume-control {
            &.vjs-volume-horizontal {
                margin-right: 0.5rem;
                transition: none !important;
            }
        }
    }

    .vjs-play-control {
        flex: 1 !important;
    }

    .vjs-remaining-time {
        flex: none !important;
        width: 3.125rem !important;
    }

    .vjs-subs-caps-button {
        flex: none !important;
        width: 3.125rem !important;
    }

    .vjs-playback-rate {
        flex: none !important;
        width: 3.125rem !important;
    }
}

.back-button {
    background-color: color('ui-purple');
    color: color('ui-white');
    border-width: 0;
    position: fixed;
    top: 2.5rem;
    left: 1.25rem;
    transform: rotate(90deg);

    @include breakpoint-up('md') {
        top: 1.25rem;
    }

    svg {
        transform: translateY(1px);
    }

    &:focus,
    &:hover {
        background-color: color('ui-white');
        color: color('ui-purple');

        path {
            stroke: currentColor;
        }
    }
}

.video-js {
    object-fit: cover;
    width: 100vw;
    height: calc(100vh - 97px);
    position: fixed;
    top: 0;
    left: 0;
    @include breakpoint-up('sm') {
        height: 100vh; 
    }
}

.vjs-tech {
    object-fit: cover;
}

.storypoint-container {
    padding: 0;
    background: linear-gradient(180deg, rgba(color('ui-black'), 0), rgba(color('ui-black'), 1) 50%);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    @include breakpoint-up('md') {
        display: flex;
        justify-content: center;

        background: none;
    }

    &.is-active{
        padding: 1.25rem;
        padding-bottom: 2rem;
        z-index: 999999;
        @include breakpoint-up('md') {
            padding: 1.25rem 2.5rem 3.635rem;
        }

    }
}

.storypoints {
    display: none;

    &.is-active{
        display: flex;
        flex-direction: column;

        @include breakpoint-up('md') {
            flex-direction: row;
        }
    }

    > * {
        flex: 1;
    }

    > * + * {
        margin: 1rem 0 0 0;

        @include breakpoint-up('md') {
            margin: 0 0 0 2rem;
        }
    }

    .storypoint-option, .storypoint-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        @include ffMalden('light');
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: color('ui-purple');
        text-transform: none;
        min-height: 5rem;
        padding: 1rem 2.5rem;
        border-width: 0;
        border-radius: 2.5rem;

        @include breakpoint-up('md') {
            width: 18.125rem;
        }

        &:focus,
        &:hover {
            background-color: color('ui-purple');
            color: color('ui-white');
        }

        &:active {
            background-color: color('ui-black');
            color: color('ui-white');
        }
    }

    .storypoint-loader {
        &:focus,
        &:hover,
        &:active {
            color: color('ui-purple');
            background-color: color('ui-white');
        }
    }

    &-start{
        position: absolute;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        z-index: 2;


        .storypoint-option{
            color: color('ui-purple');
            text-transform: uppercase;
            @include ffMalden('bold');
            font-size: rem(12);
            line-height: rem(12);
            padding: rem(16) rem(37);
            min-height:0;
            min-width: 0;
            width:fit-content;
            svg{
                margin-right: rem(10);
            }
            g {
                fill: color('ui-purple');
            }

            &:hover g,
            &:focus g {
                fill: color('ui-white');
            }
        }

        &:hover .storypoint-option{
            border: 1px solid color('ui-white');
        }
    }
}

.vjs-poster {
    background-size: cover!important;
}


.story{
    .vjs-theme-city .vjs-remaining-time{
        flex:1;
    }
    .vjs-theme-city .vjs-volume-bar.vjs-slider-horizontal{
        margin-left: auto;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .vjs-theme-city .vjs-control-bar{
        padding-bottom: 10px;
        height: 90px;
    }

    .vjs-text-track-cue{
    font-size: 16px !important;
    }

    .video-js{
        height: calc(100vh - 100px);
    }

}

:global(video::cue) {
    font-size: 19px;
}

:global(video::-webkit-media-text-track-display) {
    line-height: 19px;
}
