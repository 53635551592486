@keyframes fadein {
	from {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideOut {
	to {
		transform: translateX(100%);
	}
}
