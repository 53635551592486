// Styles for the Stories template

.stories {
	background-color: color('ui-purple');

	.site-footer {
		background-color: color('ui-purple');


        #dg_footer_wrapper{
            background-color: color('ui-purple');
        }
	}

    .page-intro-text{
        p:not(:first-child){
            margin-top:0;
        }
    }
}


// Story card styles
.story-card {
	position: relative;
}

.story-card-content {
	flex: 1;
	position: relative;

	@include breakpoint-up('md') {
		flex: 0;
		position: static;
	}
}

.story-card-meta {
	display: flex;
	justify-content: space-between;
}

//thailand stories mobile spacing for text
.th-TH {
	.story-card-number {
		margin-right: 1rem;
	}

}

.story-card-number {
	@include ffNimbusSans();
	font-size: 1.125rem;
	line-height: 2.25rem;

}

.story-card-bio {
	font-size: 1.125rem;
	line-height: 2.25rem;
	position: relative;
	z-index: 1;

	.is-active & {
		@include breakpoint-up('md') {
			font-size: 1.25rem;
			line-height: 2rem;
		}
	}
}

.story-card-title {
	font-size: 1.5rem;
	line-height: 1.875rem;
	margin: 0;
	position: relative;
	z-index: 1;

	@include breakpoint-up('md') {
		margin-top: 0.75rem;
	}

	.is-active & {
		font-size: 2.5rem;
		line-height: 2.5rem;

		@include breakpoint-up('md') {
			font-size: 3.5rem;
			line-height: 3.5rem;
			margin-top: 1rem;
		}
	}
}

.story-card-detail {
	padding: 2rem 1.25rem;
	position: relative;
	z-index: 1;
	opacity: 0;

	.is-active & {
		opacity: 1;
		animation: fadein 1s linear 1;
	}
}

.story-card-portrait {
	display: block;
}

.story-card-blurb {
	font-size: 1.125rem;
	line-height: 1.5rem;
	margin-top: auto;

	.is-active & {
		@include breakpoint-up('md') {
			font-size: 1.5rem;
			line-height: 2rem;
			margin-top: 0;
		}
	}

	.is-collapsible & {
		max-height: 10rem;
		overflow: hidden;
		transition: max-height 0.5s;
	}

	.story-card-content.is-collapsed & {
		max-height: 0;
	}
}

button.story-card-toggle {
	@include ffMalden('bold');
	font-size: 0.875rem;
	line-height: 1.75rem;
	color: color('ui-white');
	text-transform: none;
	min-width: 0;
	padding: 0;
	border: none;
	background: none;

	&:focus:enabled,
	&:hover:enabled {
		background: transparent;
	}

	&::after {
		display: inline-block;
		content: '';
		width: 14px;
		height: 7px;
		margin-left: 0.5rem;
		background-image: url('/images/icon-toggle-down-white.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transform: rotate(180deg);

		.is-collapsed & {
			transform: rotate(0);
		}
	}
}

.story-card-actions {
	display: flex;
	flex-direction: column;

	@include breakpoint-up('md') {
		flex-direction: row;
		align-items: center;
	}

	> * + * {
		margin: 2.5rem 0 0 0;

		@include breakpoint-up('md') {
			margin: 0 0 0 2rem;
		}
	}
}

.story-card-cta.with-icon {
	display: inline-flex;
	min-width: 0;
	margin-top: 2rem;
}


// Accordion styles
.accordion {
	@include breakpoint-up('md') {
		display: flex;

		> .accordion-segment {
			width: 22%;
			transition: width 0.5s;

			&.is-active {
				width: 56%;
			}
		}
	}

    &[data-slide-count="1"] {
        @include breakpoint-up('md') {
            > .accordion-segment {
                width: 0;

                &.is-active {
                    width: 100%;
                }
            }
        }
    }

    &[data-slide-count="2"] {
        @include breakpoint-up('md') {
            > .accordion-segment {
                width: 30%;

                &.is-active {
                    width: 70%;
                }
            }
        }
    }
}

.accordion-content {
	max-height: 0;
	transition: max-height 0.5s;

	.is-active & {
		max-height: 100vh;

        @media all and (-ms-high-contrast:none){
            min-height:220px;
        }
	}
}

//LAC title styling 
.es-MX, .es-CO, .es-DO {
	.story-accordion {
		.story-card-title {
			font-size: 2rem;
			@include breakpoint-up('sm') {
				font-size: 2.5rem;
			}
		}
	}
}

// Story accordion styles
.story-accordion {
	background-color: color('ui-black');

	@include breakpoint-up('md') {
		border-bottom: 2px solid color('ui-pink');
	}

	.story-card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-width: 0 0 2px 0;
		border-style: solid;
		border-image: linear-gradient(90deg, color('ui-pink'), color('ui-purple')) 2;
		overflow: hidden;
		position: relative;

		@include breakpoint-up('md') {
			min-height: 26.5rem;
			border-width: 0 2px 0 0;
			border-image: linear-gradient(0deg, color('ui-pink'), color('ui-purple')) 2;
		}

		&:last-child {
			@include breakpoint-up('md') {
				border-width: 0;
			}
		}
	}

	.story-card-tab {
		padding: 2rem 1.25rem 2.25rem;

		@include breakpoint-up('md') {
			padding: 1.5rem 2.125rem;
			margin-top: auto;
		}
	}

	.is-active .story-card-tab {
		@include breakpoint-up('md') {
			padding: 0 4rem;
		}
	}

	.story-card-number {
		@include breakpoint-up('md') {
			position: absolute;
			top: 1rem;
			left: 1.125rem;
			z-index: 1;
		}
	}

	.story-card-bio {
		margin-left: auto;

		@include breakpoint-up('md') {
			margin-left: 0;
		}
	}

	.story-card-toggle {
		display: flex;
		align-items: center;
		margin-left: 1rem;

		@include breakpoint-up('md') {
			display: none;
		}

		.is-active .story-card-toggle {
			transform: rotate(180deg);
		}
	}

	.story-card-title {
		text-align: right;
		transition: all 0.5s;

		@include breakpoint-up('md') {
			text-align: left;
		}
	}

	.story-card-detail {
		display: flex;
		flex-direction: column;
		height: 22rem;
		max-width: 17rem;

		@include breakpoint-up('md') {
			padding: 1rem 2.125rem 1.5rem;
			max-width: 30rem;
			height: auto;
		}
	}

	.is-active .story-card-detail {
		@include breakpoint-up('md') {
			padding: 2rem 4rem;
			max-width: 30rem;
		}
	}

	.story-card-cta {
		align-self: flex-start;
	}

	.story-card-portrait {
		width: auto;
		max-width: none;
		height: 100%;
		max-height: 100%;
		mask-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1));
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
	}
}

// Story grid: "Featured Stories from Around the Globe"

.story-grid {
	padding-bottom: 3.75rem;
	background-color: color('ui-black');
	background-image: url('../../img/bg-globe.svg');
	background-repeat: no-repeat;
	background-position: 175% 0;
	background-size: auto 270px;
    overflow-x: hidden;
	@include breakpoint-up('sm') {
		padding-bottom: 6.25rem;
		background-position: 130% 0;
		background-size: 60% auto;
	}
}

.story-grid-header {
	padding: 2rem 1.125rem 5rem;

	@include breakpoint-up('md') {
		padding: 6.5rem 4rem;
	}
}

.story-grid-title {
	font-size: 1.5rem;
	line-height: 1.75rem;
	letter-spacing: 0.05em;
	background-image: url('/images/icon-location.svg');
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: 2.125rem 3.7rem;
	padding-left: 3rem;

	> span {
		display: block;
		font-size: 0.875rem;
	}

	@include breakpoint-up('md') {
		font-size: 2.5rem;
		line-height: 2.75rem;
		background-size: 2.9rem 3.7rem;
		padding-left: 4.5rem;

		> span {
			font-size: 1.5rem;
		}
	}
}

.story-grid {
    position: relative;
	.story-card {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 17rem;
		overflow: visible;
		position: relative;

        @include breakpoint-up('sm') {
            min-height: 30.625rem;
        }

		@include breakpoint-up('md') {
			min-height: 33.125rem;
			width: 36rem;
			min-height: 30.625rem;
		}

		& + .story-card {
			margin-top: 3rem;
		}
	}

	.story-card-portrait {
		height: auto;
		width: auto;
		max-height: none;
		border-style: solid;
		border-color: color('ui-purple');
		border-image: linear-gradient(180deg, rgba(color('ui-purple'), 0), rgba(color('ui-purple'), 0.5)) 1;
		mask-image: none;
		position: absolute;
		top: 0;
		right: auto;
		bottom: auto;
		left: 0;
        background: black;

		> img {
			display: block;
			width: 10rem;
			height: auto;
			mask-image: linear-gradient(270deg, rgba(0,0,0,0.4), rgba(0,0,0,1));

			@include breakpoint-up('sm') {
				width: 20rem;
				mask-image: linear-gradient(270deg, rgba(0,0,0,0.4), rgba(0,0,0,1))
			}
		}
	}

	.story-card-content {
		flex: 0;

		@include breakpoint-up('md') {
			position: relative;
		}
	}

	.story-card-bio {
		font-size: 0.875rem;
		line-height: 1rem;

		@include breakpoint-up('md') {
			font-size: 1.125rem;
			line-height: 2rem;
		}
	}

	.story-card-title {
		font-size: 1.125rem;
		line-height: 1.5rem;
		margin-top: 0.625rem;

		@include breakpoint-up('sm') {
			font-size: 2rem;
			line-height: 2.5rem;
			margin-top: 1rem;
		}
	}

	.story-card-blurb {
		font-size: 0.875rem;
		line-height: 1rem;

		@include breakpoint-up('sm') {
			font-size: 1.125rem;
			line-height: 2rem;
			margin-top: 1.25rem;
		}
	}

	.story-card.is-primary {
		margin-bottom: 4rem;

        @include breakpoint-up('sm') {
			width: 100%;
            max-width: 100%;
		}


		@include breakpoint-up('md') {
			width: 53rem;
            max-width: 100%;
			min-height: 34.875rem;
		}

		.story-card-portrait {
			border-width: 0 2.5rem 2rem 0;
			border-image: linear-gradient(180deg, rgba(color('ui-purple'), 0), rgba(color('ui-purple'), 255)) 1;

			@include breakpoint-up('md') {
				border-width: 0 5rem 4.25rem 0;
			}

			> img {
				width: 12rem;

				@include breakpoint-up('sm') {
					width: 20rem;
				}
			}
		}

		.story-card-content {
			padding: 4rem 1.25rem 1rem 9.5rem;

            @include breakpoint-up('sm') {
                padding: 11.5rem 1.25rem 1rem 18rem;
            }

			@include breakpoint-up('md') {
				padding: 9.5rem 1.25rem 1rem 22rem;
			}
		}

		.story-card-bio {
			font-size: 1.125rem;
			line-height: 2rem;
		}

		.story-card-title {
			font-size: 1.5rem;
			line-height: 2.5rem;

			@include breakpoint-up('sm') {
				font-size: 3.5rem;
				line-height: 3.5rem;
			}
		}

		.story-card-toggle {
			display: none;
		}

		.story-card-blurb {
			font-size: 1.125rem;
			line-height: 1.375rem;

			@include breakpoint-up('sm') {
				font-size: 1.25rem;
				line-height: 1.875rem;
				padding-right: 2.5rem;
			}
		}
	}

	.story-card.is-right {

		@include breakpoint-up('sm') {
			width: 32rem;
			margin-left: auto;
		}

		.story-card-portrait {
			right: 0rem;
			left: auto;
			border-width: 0 0 1rem 1rem;

			@include breakpoint-up('md') {
                right: 3rem;
				border-width: 0 0 2.5rem 2.5rem;
			}

			> img {
				mask-image: linear-gradient(90deg, rgba(0,0,0,0.4), rgba(0,0,0,1));
			}
		}

		.story-card-content {
			padding: 1rem 8.75rem 1rem 1.25rem;

			@include breakpoint-up('sm') {
				padding: 9rem 16.5rem 1rem 1.25rem;
			}
		}
	}

	.story-card.is-left {
        @include breakpoint-up('md') {
            width: 38rem;
        }

		@include breakpoint-up('md') {
			margin-left: 18.75rem;
		}

		.story-card-portrait {
			border-width: 1rem 1rem 0 0;
			border-image: linear-gradient(0deg, rgba(color('ui-purple'), 0), rgba(color('ui-purple'), 0.5)) 1;

			@include breakpoint-up('md') {
				border-width: 2.5rem 0 0 2.5rem;
			}
		}

		.story-card-content {
			padding: 1rem 1.25rem 1rem 8.75rem;

			@include breakpoint-up('sm') {
				padding: 14.312rem 1.25rem 1rem 18.625rem;
			}
		}
	}

	.story-card.is-right,
	.story-card.is-left {
		.story-card-toggle {
			margin-top: 0.5rem;
		}


	}
}

.story-anchor{
    position: absolute;
    top:0;
    left:0;
    text-align: center;
    width:100vw;
}

// Optional Grid layout for Story Grid

@supports(display: grid) {

	@include breakpoint-up('md') {

		.story-grid {
			display: grid;
			grid-template-columns: 22% 37% 3% 3% auto;
			grid-template-rows: repeat(3, auto) 5.875rem repeat(2, auto);

			.story-grid-header {
				grid-row: 1 / span 1;
				grid-column: 1 / 4;
			}

			.story-card.is-primary {
				grid-row: 2 / 4;
				grid-column: 1 / 4;
				margin: 0;
			}

			.story-card.is-right {
				grid-row: 3 / 6;
				grid-column: 3 / 6;
				justify-self: end;
				margin-top: 0;
			}

			.story-card.is-left {
				grid-row: 5 / 7;
				grid-column: 2 / 5;
				margin: 0;
			}
		}
	}
}


// Story carousel: "More Stories from Around the Globe"
.story-carousel {
	background-color: color('ui-light-grey');
}

.story-carousel-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 2.5rem 1.25rem;

	@include breakpoint-up('md') {
		padding: 4.375rem 8.75rem;
	}

	&::after {
		flex: 1 0 auto;
		display: block;
		content: '';
		width: 114px;
		height: 14px;
		background-image: url('/images/ui-long-arrow-right.svg');
		background-repeat: no-repeat;
		background-position: 100% 100%;

		@include breakpoint-up('md') {
			display: none;
		}
	}

	> .swiper-scrollbar {
		display: none;

		@include breakpoint-up('md') {
			display: flex;
		}
	}
}

.story-carousel-title {
	font-size: 1.5rem;
	line-height: 2rem;
	letter-spacing: 0.05em;
	color: color('ui-purple');

	@include breakpoint-up('md') {
		font-size: 1.75rem;
		line-height: 2rem;
	}
}

.story-carousel {
	.story-card {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 10rem;
		height: 20.5rem;
		padding: 1rem;
		background-color: color('ui-black');
		border-right:  2px solid color('ui-purple');
		border-image: linear-gradient(180deg, color('ui-light-grey'), color('ui-purple') 50%) 1;
		position: relative;

		@include breakpoint-up('md') {
			width: 19rem;
			height: 36rem;
			padding: 1.25rem 1.25rem 2.5rem 1.25rem;
		}
	}

	.story-card-portrait {
		width: 100%;
		height: auto;
		max-height: none;
		mask-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,1));
		position: absolute;
		top: 0;
		left: 0;
	}

	.story-card-bio {
		font-size: 0.875rem;
		line-height: 1rem;

		@include breakpoint-up('md') {
			font-size: 1.125rem;
			line-height: 2rem;
		}
	}

	.story-card-title {
		font-size: 0.875rem;
		line-height: 1.25rem;
		margin-top: 0.75rem;

		@include breakpoint-up('md') {
			font-size: 1.5rem;
			line-height: 2.5rem;
		}
	}

	.story-card-content {
		flex: 0;
		margin-top: 1rem;
		position: relative;
	}

	.story-card-blurb {
		font-size: 0.875rem;
		line-height: 1.25rem;

		@include breakpoint-up('md') {
			font-size: 1.125rem;
			line-height: 1.5rem;
		}
	}

	.story-card-cta {
		flex: 0 0 auto;
		align-self: flex-end;
		width: 3rem;
		height: 3rem;
		padding: 0.5rem;
		border: none;
		margin-top: 1.5rem;
		position: relative;

		.cta-icon {
			height: 1.125rem;
		}

		.cta-text {
			display: none;
		}
	}
}

button.story-card-toggle:not(.vjs-button):focus:enabled,
button.story-card-toggle:not(.vjs-button):hover:enabled {
    background:transparent;
}


.story-card-cta.story-card-expandable-cta{
    flex: 0 0 auto;
    align-self: flex-end;
    width: auto;
    height: 3rem;
    padding: 0.5rem;
    border: none;
    margin-top: 1.5rem;
    position: relative;
    max-width: 3rem;
    overflow: hidden;
    justify-content: left;
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;

    transition: max-width 0.4s;

    span{
        &.cta-text{
            min-width: 80px;
            padding-right: 0.5rem;
            opacity: 0;
            transition: opacity 0.2s 0.3s;
        }

        &.cta-icon{
            width:1.125rem;
            height: 1.125rem;

        }
    }

    &:hover{
        max-width: 10rem;
        color: color('ui-purple');
        background-color: color('ui-white');

        span{
            &.cta-text{
                opacity: 1;
            }
        }
    }
    &:focus{
        background-color: color('ui-purple');
        color: color('ui-white');
    }
}

//mena specific fixes (arabic rtl)
.middle-east-north-africa{
	.story-accordion {
		.story-card-title {
			@include breakpoint-up('md') {
				text-align: right;
			}
		}

		.is-active {
			.story-card-tab{
				@include breakpoint-up('md') {
					max-width: 30rem;
					padding: 0rem 4rem;
				}
			}
		}

		.story-card-meta{
			@include breakpoint-up('md') {
				justify-content: flex-end ;
				
				.story-card-bio{
				}
			}
		}
	}
}