// Styles and layout for the survey intro page

.locale {
	background-color: color('ui-purple');

	.content {
		padding: 2rem 1.25rem;

		@include breakpoint-up('sm') {
			padding: 1.25rem 2rem 1.5rem;
		}
	}

	.content-inner {
        width:100%;
		padding: 0 0rem 0 0;
        max-width: 900px;
        margin: 0 auto;
	}
}

.locale-title {
	margin-top: 2.25rem;

	@include breakpoint-up('sm') {
		margin-top: 6rem;
	}

	h1 {
		font-size: 2.5rem;
		line-height: 3rem;

		@include breakpoint-up('sm') {
			font-size: 5rem;
			line-height: 5rem;
		}
	}
}

.locale-text {
	margin-top: 2rem;

	@include breakpoint-up('sm') {
		margin-top: 3rem;
	}

	p {
		font-size: 1.5rem;
		line-height: 2rem;

		@include breakpoint-up('sm') {
			font-size: 2rem;
			line-height: 2.625rem;
		}
	}
}

.locale-form {
    width:100%;
    max-width: 900px;
    margin: 3rem auto 0;

	@include breakpoint-up('sm') {
		padding: 0;
	}

	.form-actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;

		@include breakpoint-up('sm') {
			margin-top: 4.5rem;
		}
	}
}

.facilitated {
    .locale-title {
        h1 {
            font-size: rem(24);
            line-height: rem(32);

            @include breakpoint-up('sm') {
                font-size: rem(40);
                font-size: rem(40);
            }
        }
    }

    .locale-text {
        margin-top: rem(26);

        @include breakpoint-up('sm') {
            margin-top: rem(24);
        }

        p {
            font-size: rem(18);
            line-height: rem(24);

            @include breakpoint-up('sm') {
                font-size: rem(24);
                line-height: rem(35);
                letter-spacing: 0.12px;
            }
        }
    }

    .locale-form {
        margin: rem(20) auto 0;

        .chosen-single{
            span{
                font-size: rem(18);
            }
        }

        .form-actions{
            margin-top: rem(40);
            @include breakpoint-up('sm') {
                margin-top: rem(45);
            }
            justify-content: flex-start;
        }
    }
}
