// Mixins for imported fonts
$font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

@font-face {
    font-family: 'NimbusSanExtBla W05 Regular';
    src: url("/fonts/NimbusSanExtBlaW05-Regular.woff2") format('woff2'), url("/fonts/NimbusSanExtBlaW05-Regular.woff") format('woff');
}

@mixin ffNimbusSans() {
	font-family: 'NimbusSanExtBla W05 Regular', $font-stack;
	font-weight: 400;
    src: url("/fonts/NimbusSanExtBlaW05-Regular.woff"),
}

$ffMalden: (
	"light": (
		"weight": 300,
		"regular": "Overlook Sans W05 Light",
		"italic": "Overlook Sans W05 Light Italic",
        "src2": "/fonts/MaldenSansW05-Light.woff2",
        "src2-italic": "/fonts/MaldenSansW05-LightItalic.woff2",
        "src": "/fonts/MaldenSansW05-Light.woff",
        "src-italic": "/fonts/MaldenSansW05-LightItalic.woff"
	),
	"regular": (
		"weight": 400,
		"regular": "Overlook Sans W05 Regular",
		"italic": "Overlook Sans W05 Italic",
        "src2": "/fonts/MaldenSansW05-Regular.woff2",
        "src2-italic": "/fonts/MaldenSansW05-Italic.woff2",
        "src": "/fonts/MaldenSansW05-Regular.woff",
        "src-italic": "/fonts/MaldenSansW05-Italic.woff"
	),
	"bold": (
		"weight": 700,
		"regular": "Overlook Sans W05 Bold",
		"italic": "Overlook Sans W05 Bold Italic",
        "src2": "/fonts/MaldenSansW05-Bold.woff2",
        "src2-italic": "/fonts/MaldenSansW05-BoldItalic.woff2",
        "src": "/fonts/MaldenSansW05-Bold.woff",
        "src-italic": "/fonts/MaldenSansW05-BoldItalic.woff"
	),
	"black": (
		"weight":	900,
		"regular": "Overlook Sans W05 Black",
		"italic": "Overlook Sans W05 Black Italic",
        "src2": "/fonts/MaldenSansW05-Black.woff2",
        "src2-italic": "/fonts/MaldenSansW05-BlackItalic.woff2",
        "src": "/fonts/MaldenSansW05-Black.woff",
        "src-italic": "/fonts/MaldenSansW05-BlackItalic.woff"
	)
);

@each $type, $data in $ffMalden {
    @font-face {
        font-family: map-get($data, 'italic');
        src: url(map-get($data, "src2-italic")) format("woff2"), url(map-get($data, "src-italic")) format("woff");
    }
    @font-face {
        font-family: map-get($data, 'regular');
        src: url(map-get($data, "src2")) format("woff2"), url(map-get($data, "src")) format("woff");
    }
}


@mixin ffMalden($weight, $style: 'regular') {

	@each $type, $data in $ffMalden {
		@if $type == $weight {
			@if $style == 'italic' {
        		font-family: map-get($data, 'italic');
                src: url(map-get($data, "src2-italic")) format("woff2"), url(map-get($data, "src-italic")) format("woff");
			} @else {
				font-family: map-get($data, 'regular');
                src: url(map-get($data, "src2")) format("woff2"), url(map-get($data, "src")) format("woff");
			}
			// Set font-weight: 400 and font-style: normal to present actual font weight and prevent additional obliquing, respectively :(
			font-weight: map-get($data, "weight");
			font-style: normal;
        }
	}
}
