// Styles for the Advice (Post Story) template

.advice {
	// .site-header {
	// 	background-color: color('ui-purple');
	// }

}
body.advice.india-bengali, body.advice.india-marathi, body.advice.india-telugu, body.advice.india-kannada{
	h2 {
		@include ffMalden('light');
	}
}

.advice-header-section {
	background-color: color('ui-purple');
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.advice-header {
	background-color: color('ui-white');
	color: color('ui-black');
    position: relative;
    padding-bottom: 60px;
    position: relative;


}

.advice-header-content {
	padding: 2.25rem;

	@include breakpoint-up('md') {
		display: flex;
		align-items: flex-start;
		padding: 5.25rem 6.25rem 1.5rem 8.75rem;
	}
}

.advice-header-inner {
	padding: 0 1.25rem;

	@include breakpoint-up('md') {
		padding: 0;
	}
}

//LAC title styling 
.es-MX, .es-CO, .es-DO {
	.advice-title {
		font-size: 2rem;
		@include breakpoint-up('sm') {
			font-size: 2.5rem;
		}
	}
}

.advice-title {
	font-size: 2.5rem;
	line-height: 3.125rem;
	color: color('ui-purple');
	word-break: break-word;
	@include breakpoint-up('sm') {
		font-size: 4rem;
		line-height: 4.625rem;
		word-break: normal;
	}
}

.advice-header-inner ol {
	font-size: 1.125rem;
	line-height: 1.5rem;
	margin-top: 2rem;
    padding-right: 20px;

    li, p {
        @include breakpoint-up('sm') {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    li{
        @include breakpoint-up('sm') {
            margin-top: 4.375rem;
        }
    }

	> li + li {
		margin-top: 1.5rem;

		@include breakpoint-up('md') {
			margin-top: 2rem;
		}
	}
}

.advice-carousel-wrapper {
    position:relative;
    @include breakpoint-up('sm') {
		padding: 0 4rem;
	}
    @include breakpoint-up('md') {
        padding: 0;
		max-width: 29.25rem;
	}

    .swiper-scrollbar {
        height: 15px;
        width: 11rem;
        background-color: transparent;
        background-image: url('/images/ui-arrow-range.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
        position: relative;
		width: calc(50% - 0.75rem);
		right: 0.75rem;
		bottom: 6rem;
		left: 50%;
        z-index: 10000;
		@include breakpoint-up('md') {
			width: 11.25rem;
			position: absolute;
			right: -3.75rem;
			left: auto;
		}
	}


}

.advice-carousel {
	margin-top: 1.5rem;

	@include breakpoint-up('md') {
		margin-top: 0;
	}

	// .swiper-scrollbar {
	// 	width: calc(50% - 0.75rem);
	// 	right: 0.75rem;
	// 	bottom: 6rem;
	// 	left: 50%;

	// 	@include breakpoint-up('sm') {
	// 		width: 11.25rem;
	// 		position: absolute;
	// 		right: 1.25rem;
	// 		left: auto;
	// 	}
	// }

	.swiper-pagination {
		width: auto;
		top: 1rem;
		right: auto;
		bottom: auto;
		left: 50%;

		@include breakpoint-up('md') {
			right: 1rem;
			left: auto;
		}

	}

	.hint-text {
		color: color('ui-white');
		text-align: center;
		padding: 0 1.25rem;
		position: absolute;
		right: 0;
		bottom: 2.25rem;
		left: 0;
		z-index: 1;

		@include breakpoint-up('md') {
			text-align: right;
			margin-right: 0;
			margin-left: 0;
			bottom: 1.25rem;
		}

		p {
			font-size: 0.875rem;
			line-height: 1.5rem;
		}
	}
}

.advice-carousel-slide {
	display: flex;
	justify-content: flex-start;
}

.advice-carousel-portrait {
	display: block;
	width: 21.25rem;
	height: auto;
	border-width: 0 0 4rem 4rem;
	border-style: solid;
	border-color: color('ui-purple');
	border-image: linear-gradient(180deg, color('ui-white') 20%, color('ui-purple')) 1;
	align-self: flex-start;
	@include breakpoint-up('sm') {
        min-width: 468px;
		width: 100%;
	}
}


.advice-new-header {
	position: relative;
	background-position: center center;
	background-size: cover;
	background-color: color('ui-purple');
	background-image: url("/images/texture-bokeh-2.png");
	min-height: 100vh;

	.site-header {
		padding: 2.25rem 1.25rem;
		background: none;

		@include breakpoint-up('md') {
			padding: 1.25rem;
		}
	}

	.advice-new-header-inner {
		padding: 1.25rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
        margin-bottom: auto;
        margin-top: auto;
		max-width: 1024px;

		@include breakpoint-up('md') {
			padding: 0.75rem 8.625rem;
		}

	}

	.advice-content-footer {
		position: static;
		padding: 2.675rem 0 8.5rem 0;

		.advice-content-actions {
			justify-content: flex-start;
			flex-flow: wrap;

			.advice-content-advance {
				align-items: center;
				margin-top: 2rem;

				@include breakpoint-up('xs') {
					margin-top: 0;
				}
			}
		}
	}

	h2 {
		font-size: 2.5rem;
		line-height: 3.125rem;
		margin-bottom: 2rem;
		word-break: break-word;

		@include breakpoint-up('md') {
			font-size: 4rem;
			line-height: 4.625rem;
			margin-bottom: 1.75rem;
			word-break: normal;
		}
	}

	p {
		font-size: 1.25rem;
		line-height: 1.875rem;

		@include breakpoint-up('sm') {
			font-size: 1.75rem;
			line-height: 2.375rem;
		}

		* + p {
			margin-top: 1.875rem;

			@include breakpoint-up('sm') {
				margin-top: 2.375rem;
			}
		}
	}

	ul,
	ol {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.75rem;
			line-height: 2.625rem;
		}

		> li {
			padding-left: 2.25rem;

			@include breakpoint-up('sm') {
				padding-left: 3rem;
			}

			& + li {
				margin-top: 1.5rem;

				@include breakpoint-up('sm') {
					margin-top: 2.625rem;
				}
			}
		}
	}

	strong {
		text-decoration: underline;
		text-decoration-color: color('ui-purple');
		text-decoration-thickness: 0.375rem;
	}


}

// Content sections
.advice-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	padding: 1.25rem;
	position: relative;
	background-position: center center;
	background-size: cover;

	&:last-child {
		background-color: color('ui-purple');
	}

	@include breakpoint-up('md') {
		padding: 5.75rem 8.625rem;
	}

	h2 {
		font-size: 2.5rem;
		line-height: 3.125rem;
		margin-bottom: 2rem;
		word-break: break-word;

		@include breakpoint-up('md') {
			font-size: 6.25rem;
			line-height: 6.25rem;
			margin-bottom: 3rem;
		}
	}

	p {
		font-size: 1.25rem;
		line-height: 1.875rem;

		@include breakpoint-up('sm') {
			font-size: 1.75rem;
			line-height: 2.375rem;
		}

		* + p {
			margin-top: 1.875rem;

			@include breakpoint-up('sm') {
				margin-top: 2.375rem;
			}
		}
	}

	ul,
	ol {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.75rem;
			line-height: 2.625rem;
		}

		> li {
			padding-left: 2.25rem;

			@include breakpoint-up('sm') {
				padding-left: 3rem;
			}

			& + li {
				margin-top: 1.5rem;

				@include breakpoint-up('sm') {
					margin-top: 2.625rem;
				}
			}
		}
	}

	strong {
		text-decoration: underline;
		text-decoration-color: color('ui-purple');
		text-decoration-thickness: 0.375rem;
	}
}

.advice-content-inner {
	max-width: 63rem;
}

.advice-content-footer {
	padding: 2.25rem 1.25rem;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
}

.advice-content-advance {
	display: flex;
	justify-content: flex-end;
	@include ffMalden('bold');
	font-size: 0.875rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	text-decoration: none;
	color: color('ui-white');

		flex-direction: row-reverse;

	> img {
		width: 2.375rem;
		height: auto;
		margin-right: 1rem;


		@include breakpoint-up('sm') {
			width: 1.5rem;
		}
	}
}

.advice-content-actions {
	display: flex;
	justify-content: flex-start;

	.cta {
		min-width: 9rem;
	}

	> * + * {
		margin-left: 1rem;
	}
}

#section-7 {
	.advice-content-inner {
		max-width: 700px;

		h2 {
			font-size: 2.5rem;
			line-height: 3.125rem;
			margin-bottom: 2rem;

			@include breakpoint-up('md') {
				font-size: 4rem;
				line-height: 4.625rem;
				margin-bottom: 1.75rem;
			}

		}
	}

	.advice-content-footer {
		padding: 4.0625rem 0 0;
		position: static;
	}

}