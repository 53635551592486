// Styles for the thanks (Post Story) template


.thanks-header-section {
	background-color: color('ui-purple');
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.thanks-header {
	position: relative;
	background-position: center center;
	background-size: cover;
	background-color: color('ui-purple');
	background-image: url("/images/texture-bokeh-2.png");
	min-height: 100vh;

	.site-header {
		padding: 1.25rem;
		background: none;

		@include breakpoint-up('md') {
			padding: 1.25rem;
		}
	}

	.thanks-header-inner {
		padding: rem(20) rem(20) rem(130);
		display: flex;
		flex-direction: column;
		justify-content: center;
        margin-bottom: auto;
        margin-top: auto;
		max-width: 1024px;

		@include breakpoint-up('md') {
			padding: rem(12) rem(138) rem(130);
		}
	}

	h2 {
		font-size: 2.5rem;
		line-height: 3.125rem;
		margin-bottom: 2rem;
		word-break: break-word;

		@include breakpoint-up('md') {
			font-size: 4rem;
			line-height: 4.625rem;
			margin-bottom: 1.75rem;
			word-break: normal;
		}
	}

	p {
		font-size: 1.25rem;
		line-height: 1.875rem;

		@include breakpoint-up('sm') {
			font-size: 1.75rem;
			line-height: 2.375rem;
		}

		* + p {
			margin-top: 1.875rem;

			@include breakpoint-up('sm') {
				margin-top: 2.375rem;
			}
		}
	}

	ul,
	ol {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.75rem;
			line-height: 2.625rem;
		}

		> li {
			padding-left: 2.25rem;

			@include breakpoint-up('sm') {
				padding-left: 3rem;
			}

			& + li {
				margin-top: 1.5rem;

				@include breakpoint-up('sm') {
					margin-top: 2.625rem;
				}
			}
		}
	}

	strong {
		text-decoration: underline;
		text-decoration-color: color('ui-purple');
		text-decoration-thickness: 0.375rem;
	}
}
