// Style for the Homepage template
.es-MX, 
.es-CO, 
.es-DO,
.es-PA,
.es-CR,
.es-PR,
.es-SV,
.es-HN,
.es-NI,
.es-GT,
.pt-BR {
    .hp-intro {
        @include breakpoint-up('md') {
            justify-content: space-between;
        }
    }

    .hp-title {
        font-size: 11vw !important;
        line-height: 10vw !important;
        @include breakpoint-up('sm') {
            font-size: 6vw !important;
            line-height: 5vw !important;
            width: 50%;
        }
    }

    .hp-intro-content {
        @include breakpoint-up('md') {
            width: 50%;
        }
    }
}

// Homepage video background

.video-bg {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;

	video {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	&::after {
		@include breakpoint-up('md') {
            animation: slideOut 1s linear infinite;
            animation-play-state: paused;
            animation-delay: calc(var(--scroll) * -2s);
            animation-iteration-count: 1;
            animation-fill-mode: both;
            background-color: rgba(color('ui-purple'), 0.59);
            background-image: url('../../img/texture-tile.png');
            display: block;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: translateX(50%);
            width: 100%;
		}
	}
}

.hp-content {
	position: relative;

    .site-header{
        padding-top: 1.25rem;
    }
}


// Homepage intro section

.hp-intro {
	height: 100%;

    @include breakpoint-up('md') {
        align-items: center;
        display: flex;
        height: calc(100vh - 3.25rem);
        position: relative;
    }

    .cta-text{
        letter-spacing: 0.7px;
    }

    .hp-intro-transport {
        bottom: -2rem;
        position: relative;

        @include breakpoint-up('md') {
            bottom: 5rem;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }

        .cta-transport {
            max-width: none;
        }

        .cta-text {
            @include ffNimbusSans();
            font-size: 0.75rem;
            letter-spacing: normal;
        }

        &::after {
            background-color: #FFF;
            content: '';
            display: block;
            height: 4rem;
            left: 50%;
            position: absolute;
            top: 2rem;
            transform: translateX(-50%);
            width: 0.0625rem;
        }
    }
}

.hp-title {
	font-size: 3.375rem;
	letter-spacing: -0.01em;
    line-height: 2.8125rem;
    margin-left: -0.5rem;
    padding-bottom: 3rem;
    padding-top: 2rem;
	text-shadow: 0 0.1875rem 0.625rem rgba(0,0,0,0.27);
    text-transform: uppercase;

    @include breakpoint-up('sm') {
        font-size: 5.0625rem;
        line-height: 0.8;
        max-width: calc(50% + 0.5rem);
    }

	@include breakpoint-up('md') {
		font-size: 7.1875rem;
		line-height: 5.9375rem;
        margin-left: -2rem;
        padding-right: 2rem;
        max-width: calc(50% + 2rem);
	}
}

.hp-sub-title {
    line-height: 1.35;
}

.hp-intro-content {
    background-color: rgba(color('ui-purple'), 0.59);
    background-image: url('../../img/texture-tile.png');
    padding: 2rem 2rem 4rem;

    @include breakpoint-up('md') {
        background-color: transparent;
        background-image: none;
        margin: 0 4rem;
        //max-width: calc(50% - 8rem);
        max-width: 36.25rem;
        padding: 0;
    }

    @include breakpoint-up('lg') {
        margin: 0 6.25rem;
    }

}

.hp-intro-text {
    @include breakpoint-up('sm') {
        max-width: 50%;
    }

    @include breakpoint-up('md') {
        max-width: 100%;
    }

    h2 {
        @include ffMalden('bold');
        font-size: 1.75rem;
    }

	p {
		font-size: 1.125rem;
		line-height: 1.35;
        margin-top: 1rem;
	}

    .warning {
        font-size: 0.75rem;
        line-height: 1.35;
        margin-top: 1rem;
        opacity: 0.6;
    }

    .duration {
        background-image: url('/images/icon-timer.svg');
        background-repeat: no-repeat;
        padding-left: 2rem;
        font-size: 0.875rem;
        text-transform: uppercase;
    }


	.cta {
		margin-top: 2rem;
        min-width: 11rem;
	}

    .cta-transport {
        margin-top: 4.5rem;
        min-width: 11rem;

        @include breakpoint-up('sm') {
            margin-top: 6.5rem;
        }

        &.transport-two {
            justify-content: flex-start;

            @include breakpoint-up('sm') {
                position: relative;
            }
        }
    }
}

// Homepage text section

.hp-section {
	min-height: 100vh;
	padding: 4.5rem 2.125rem 4.5rem 1.25rem;

	@include breakpoint-up('sm') {
		padding: 6.25rem 30% 5.375rem 25%;
	}

	.cta {
		margin-top: 2rem;

		@include breakpoint-up('sm') {
			margin-top: 1.5rem;
		}
	}
}

.hp-section-1 {
    padding: 6.25rem 2.5rem 2.5rem 2.5rem;

    &_text {
        display: flex;
        flex-direction: column;
        width:100%;

        @include breakpoint-up('sm') {
            width: 46%;
            margin: auto;
        }
    }

    .transport-two {
        width:100%;
    }

    .cta {
        max-width: 13rem;
    }
}

.hp-section-2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 3.25rem);
    margin: 10rem auto 0;
    padding: 1rem;
    text-align: center;

    @include breakpoint-up('sm') {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    @include breakpoint-up('md') {
        height: calc(100vh - 3.25rem);
        max-width: 52rem;
        padding: 0 2.5rem;
        position: relative;
    }

    .hp-section-title {
        font-size: 1.375rem;
        line-height: 1.35;
        margin-bottom: 7.5rem;
        position: relative;
        text-transform: uppercase;

        @include breakpoint-up('sm') {
            font-size: 1.5rem;
        }

        @include breakpoint-up('md') {
            font-size: 2.5rem;
        }

        &::after {
            background-color: #FFF;
            content: '';
            display: block;
            height: 4rem;
            left: 50%;
            position: absolute;
            top: 3.5rem;
            transform: translateX(-50%);
            width: 0.0625rem;
        }
    }

    .hp-section-text {
        p {
            font-size: 1.25rem;
        }
    }

    .cta-link {
        background-image: url('/images/icon-information.svg');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 1.5rem auto;
        color: #FFF;
        display: inline-block;
        font-size: 0.875rem;
        letter-spacing: 0.125rem;
        margin-top: 1.5rem;
        padding: 1rem 0 1rem 2.5rem;
        text-decoration: none;
        text-transform: uppercase;
    }
}


.hp-section-text {
	margin-top: 1.5rem;

	@include breakpoint-up('sm') {
		margin-top: 2rem;
	}

	p {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.5rem;
			line-height: 2rem;
		}

		& + p {
			margin-top: 1.5rem;

			@include breakpoint-up('sm') {
				margin-top: 2rem;
			}
		}
	}
}


.home.incentivised{
    .partner-logo {
        .site-logo{
            padding: 0 rem(20) rem(20);
            width: rem(191);
            height: rem(65);
            @include breakpoint-up('sm') {
                padding: rem(5) rem(25) rem(25);
                width: rem(145);
                height: rem(85);
            }

            .imported-logo{
                max-width: rem(105);
                max-height: rem(45);

                @include breakpoint-up('sm') {
                    max-width: rem(141);
                    max-height: rem(60);
                }
            }
        }

        
    }
    .hp-intro {
        height: calc(100% - 5.31rem);
        
        @include breakpoint-up('md') {
            align-items: center;
            display: flex;
            height: calc(100vh - 6.56rem);
            position: relative;

            .hp-title{
                padding-top:0;
            }
        }
    }
}

//mena specific fixes (arabic rtl)
.middle-east-north-africa{

    .hp-intro-text{
        .duration {
            background-position: right;
            padding-right: 2rem;
        }
    }

    // .hp-section-2{
    //     .cta-link {
    //         background-position: right center;
    //         padding: 1rem 2.5rem 1rem 0;
    //     }
    // }
}