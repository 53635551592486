//
// Container widths and default padding
//
main.content{
    min-height: calc(100vh - 97px);
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: color('ui-black');
  color: color('ui-white');

  @include breakpoint-down('xs') {
    width: 100%;
  }

  @include breakpoint-up('xs') {
    width: breakpoint('xs');
  }

  @include breakpoint-up('sm') {
    width: breakpoint('sm');
  }

  @include breakpoint-up('md') {
    width: breakpoint('md');
  }

  @include breakpoint-up('lg') {
    width: breakpoint('lg');
  }
}
