// General layout styles

body {
	background-color: color('ui-black');
	color: color('ui-white');
}

.site-header {
	display: flex;
	justify-content: space-between;
}

.site-logo {
	width: 9.5rem;
	font-size: 0.875rem;
	line-height: 0.875rem;
	letter-spacing: -0.01em;
	text-transform: uppercase;

    a {
        text-decoration: none;
        color: color('ui-white');
	}

	svg {
		display: block;
		width: 100%;
		height: auto;
	}
}

.icon-link-about-open {
	.a {
		fill: color('ui-white');
		stroke: color('ui-white');
	}
	.b {
		fill: color('ui-white');
	}
	.c,
	.d {
		fill: none;
	}
}

.icon-link-lang{
    text-decoration: none;
    color: color('ui-white');
    font-family: "NimbusSanExtBla W05 Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    margin-left: auto;
    margin-right: 30px;
    line-height: 32px;

    &::after{
        content: "";
        display: inline-block;
        width: 9px;
        height: 11px;
        background-image: url(/images/icon-toggle-right-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 1rem;
    }
}

.site-footer {
	background-color: color('ui-black');
	padding: 2.25rem;
	position: relative;

	@include breakpoint-up('sm') {
		padding: 3rem 0rem;
	}

    #dg_footer_wrapper{
        background-color: color('ui-black');
    }

    #dg_footer_wrapper_inner{
        @include ffMalden('light');
    }

    #dg_footer_wrapper_inner .dg_footer_row_0 a, #dg_footer_wrapper_inner .dg_footer_row_0 a:hover, #dg_footer_wrapper_inner ul li span{
        color: color('ui-white');
        @include ffMalden('light');
    }

    #dg_footer_wrapper_inner ul li:after{
        color: color('ui-white');
        margin: 0 5px;
    }

    #dg_footer_wrapper_inner .dg_footer_item_last{
        float:none;
        display: block;
    }
}

.site-footer-heading {
	@include ffMalden('regular');
	font-size: 1.125rem;
	line-height: 2.125rem;
	text-align: center;

	@include breakpoint-up('sm') {
		font-size: 1rem;
		text-align: left;
	}
}

.share-icons {
	display: flex;
	justify-content: center;

	@include breakpoint-up('sm') {
		justify-content: flex-start;
	}

	li {
		list-style-type: none;
	}

	> * + * {
		margin-left: 1rem;
	}
}

.page-intro {
	padding: 2.25rem 1.25rem;

	@include breakpoint-up('sm') {
		padding: 1.5rem 1.25rem 4.5rem;
	}
}

.page-title {
	margin-top: 2.6875rem;

	@include breakpoint-up('sm') {
		padding: 0 7.5rem;
	}
}

.page-intro-text {
	margin-top: 1.5rem;

	@include breakpoint-up('sm') {
		padding: 0 7.5rem;
		margin-top: 0.75rem;
	}

	p {
		font-size: 1.125rem;
		line-height: 1.5rem;

		@include breakpoint-up('sm') {
			font-size: 1.5rem;
			line-height: 2.25rem;
		}
	}
}


.site-cookie{

	justify-content: space-between;
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    width: 100vw;
    background-color: color('ui-purple');
    padding:1.25rem;
    align-items: center;

    @include breakpoint-up('sm') {
        display: flex;
    }
}

.cookie-header{
    color: color('ui-white');
    font-family: "NimbusSanExtBla W05 Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.875rem;
    min-width:190px;
    line-height:1;
    margin-bottom: 1rem;

    @include breakpoint-up('sm') {
        margin-bottom: 0;
    }
}

.cookie-policy{
    color: color('ui-white');
    font-size: 0.875rem;
    line-height: 1.4;
    a{
        color: color('ui-white');
        font-size: 0.875rem;
        line-height: 1.4;
    }
    margin-bottom: 1rem;

    p{
        max-width: 900px;
    }

    @include breakpoint-up('sm') {
        margin-bottom: 0;
    }
}

.cookie-accept{
    color: color('ui-purple');
}

.hide {
    display: none !important;
}

.partner-logo{
    .imported-logo{
        max-width: rem(105);
        max-height: rem(45);

        @include breakpoint-up('sm') {
            max-width: rem(141);
            max-height: rem(60);
        }
    }
}