//
// Form controls and other interactive elements
//

// General form controls
input[type=text],
input[type=password],
input[type=email],
input[type=url],
select,
textarea {
    @include ffMalden('light');
    line-height: 1.125rem;
    background-color: transparent;
    padding: calc(0.5rem - 1px) 2.225rem calc(0.5rem - 1px) 1.125rem;
    border: 1px solid color('ui-white');
    border-radius: 0.3125rem;
    color: color('ui-white');
}

select {
    appearance: none;
    background-image: url(/images/ui-select_handle.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 0.7rem) 50%;
    background-size: 20px 20px;
    height: 2.5rem;
    padding-right: 2.225rem;

    option, optgroup{
        color: black;
        background-color: white;
    }
}

textarea {
    @include ffMalden('regular');
    font-size: 0.875rem;
    line-height: 1.875rem;
    color: color('ui-black');
    background-color: color('ui-light-grey');

    @include breakpoint-up('sm') {
        font-size: 1.125rem;
    }
}

button:not(.vjs-button),
input[type=button],
input[type=submit],
input[type=reset],
.cta {
    background-color: color('ui-white');
    color: color('ui-black');
    min-width: 11rem;
    padding: 1rem 1.75rem;
    border: 1px solid color('ui-white');
    border-radius: 1.75rem;
    @include ffMalden('bold');
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.05em;

    @include breakpoint-up('sm') {
        min-width: 11rem;
        padding: 1.125rem 1.75rem;
    }

    &.alt {
        background-color: color('ui-purple');
        border-color: color('ui-purple');
        color: color('ui-white');
    }

    &.with-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.875rem 1.75rem;
        color: color('ui-purple');

        &:active {
            background-color: color('ui-purple');
            border-color: color('ui-purple');
            color: color('ui-white');
        }

        > * + * {
            margin-left: 1rem;
        }

        .btn-icon,
        .cta-icon {
            height: 0.875rem;
            width: auto;
        }

        g {
            fill: color('ui-purple');
        }
        &:active g {
            fill: color('ui-white');
        }
        &:hover,
        &:focus g {
            fill: color('ui-white');
        }
    }

    &:focus:enabled,
    &:hover:enabled,
    &:focus,
    &:hover{
        background-color: color('ui-black');
        border-color: color('ui-white');
        color: color('ui-white');
        background-image: none;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.cta {
    display: inline-block;
    min-width: 11rem;
    text-decoration: none;
}

.cta-transport {
    @include ffMalden('bold');
    font-size: 0.75rem;
    line-height: 1rem;
    color: color('ui-white');
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 10.5rem;

    &:focus,
    &:hover {
        text-decoration: underline;
        background-color: transparent;
        border-color: transparent;
    }

    .cta-icon {
        margin-top: 1rem;
    }
}

.cta-minor {
    background-color: transparent;
    color: color('ui-white');
    text-decoration: none;
    min-width: 9.5rem;
    padding-right: 0;
    padding-left: 0;
    border-color: transparent;

    &:focus,
    &:hover {
        text-decoration: underline;
        background-color: transparent;
        border-color: transparent;
    }
}

.cta-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;
}

// Field with label

.field-with-label {

    label {
        @include ffMalden('bold');
        display: block;
        font-size: 0.875rem;
        line-height: 2.625rem;
    }

    input,
    select {
        display: block;
        width: 100%;
    }
}


// Custom radio inputs
.radio-with-label {
    display: flex;
    align-items: center;

    @include breakpoint-up('sm') {
        flex-direction: column;
    }

    &::before {
        display: block;
        content: '';
        height: 2px;
        background: linear-gradient(90deg, color('ui-purple'), color('ui-pink'));
        width: 10%;

        @include breakpoint-up('xs') {
            width: 40%;
        }

        @include breakpoint-up('sm') {
            width: 40%;
        }

        @include breakpoint-up('sm') {
            height: 4.875rem;
            width: 2px;
            background: linear-gradient(180deg, color('ui-purple'), color('ui-pink'));
        }
    }

    label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        font-size: 1rem;
        line-height: 1.25rem;

        @include breakpoint-up('xs') {
            width: 50%;
        }

        @include breakpoint-up('sm') {
            width: 50%;
        }

        @include breakpoint-up('sm') {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }

        @include breakpoint-up('sm') {
            flex-direction: column;
            text-align: center;
            width: auto;
        }

        &::before {
            display: block;
            content: '';
            //width: 2.625rem;
            padding-right: 1.25rem;
            width: 2.625rem;
            height: 2.625rem;
            border: 4px solid transparent;
            border-radius: 50%;
            //margin: 0 1.25rem 0 0;

            @include breakpoint-up('sm') {
                margin: 0 0 1rem 0;
            }
        }

        &::after {
            display: block;
            content: '';
            width: 1.125rem;
            height: 1.125rem;
            border: 2px solid color('ui-white');
            border-radius: 50%;
            position: absolute;
            left: 0.75rem;
            top: 0.75rem;

            @include breakpoint-up('sm') {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
    }

    input:focus + label {
        @include ffMalden('bold');
    }

    input:checked + label {
        @include ffMalden('bold');
    }
    input:checked + label::before {
        border-color: color('ui-pink');
    }
    input:checked + label::after {
        background-color: color('ui-white');
    }
}

.radio-group {
    display: flex;
    flex-direction: column;

    > * + * {
        margin: 1.5rem 0 0 0;
    }

    @include breakpoint-up('sm') {
        flex-direction: row;
        justify-content: center;

        > * {
            flex: 0 1 10rem;
        }

        > * + * {
            margin: 0 0 0 1.5rem;
        }
    }
}

.textarea-group {
    @include breakpoint-up('md') {
        display: flex;
        justify-content: center;
    }

    label {
        @include ffNimbusSans();
        font-size: 0.875rem;
        line-height: 1.875rem;
        letter-spacing: 0.005em;
    }

    textarea {
        width: 100%;
        min-height: 2.5rem;

        @include breakpoint-up('sm') {
            width: 33.5rem;
        }
    }
}

.field-group {

    @include breakpoint-up('sm') {
        display: flex;
    }

    > * {
        flex: 0 1 21rem;
    }

    > * + * {
        margin-top: 0.5rem;

        @include breakpoint-up('sm') {
            margin-top: 0;
            margin-left: 2.5rem;
        }
    }
}

.form-actions {

    @include breakpoint-up('sm') {
        display: flex;
        justify-content: space-between;
    }
}

// Progress meter
.progress-meter {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 2rem;

    > * + * {
        margin-left: 1rem;
    }
}

.progress-meter-current {
    @include ffMalden('black');
}

.progress-meter-range {
    display: block;
    background-color: color('ui-grey');
    height: 1px;
    width: 2rem;
    position: relative;

    @include breakpoint-up('sm') {
        width: 5rem;
    }

    &::after {
        display: block;
        content: '';
        height: 1px;
        width: 0;
        width: var(--progress);
        background-color: color('ui-white');
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s;
    }
}

.progress-meter-total {
    @include ffMalden('regular');
}


// Carousel controls
.swiper-container-horizontal .swiper-scrollbar {
    height: 15px;
    width: 11rem;
    background-color: transparent;
    background-image: url('/images/ui-arrow-range.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    position: relative;
}

.swiper-scrollbar-drag {
    position: relative;
    background-color: transparent;

    &::after {
        display: block;
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: color('ui-purple');
        position: absolute;
        left: calc(50% - 0.5rem);
    }
}

.swiper-pagination-fraction {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 2rem;
    color: color('ui-purple');

    &::before {
        flex: 0 0 auto;
        display: block;
        content: '';
        height: 1px;
        width: 3.75rem;
        background-color: color('ui-purple');
        margin: 0 1rem;
        order: 2;
    }

    .swiper-pagination-current {
        @include ffMalden('black');
        order: 1;
    }

    .swiper-pagination-total {
        order: 3;
    }
}

// videojs overrides
.video-js {
    .vjs-picture-in-picture-control, .vjs-fullscreen-control{
        display: none;
    }
    .vjs-playback-rate {
        top: 20% !important;
    }
}
.vjs-theme-city{
    --vjs-theme-city--primary: #3d23f0;
}

.vjs-menu-button-popup .vjs-menu{
    left: 1em;
}

.vjs-text-track-settings .vjs-modal-dialog-content{
    padding: 100px 24px 0 24px !important;
}

.vjs-modal-dialog.vjs-text-track-settings{
    @include breakpoint-down('md') {
        height:80% !important;
    }
}

// Chosen JS overrides
.chosen-container {
    .chosen-single {
        box-shadow: none !important;
        background-image: none !important;
        background-repeat: no-repeat !important;
        background-position: calc(100% - 0.7rem) 50% !important;
        background-size: 20px 20px !important;
        background-color: unset !important;
        height: 2.5rem !important;
        color: color('ui-white') !important;
        padding: calc(0.5rem - 1px) 2.225rem calc(0.5rem - 1px) 1.125rem !important;
        font-size: 0.875rem;

        border: 1px solid #ffffff !important;
        border-radius: 0.3125rem !important;

        @include breakpoint-up('sm') {
            font-size: 1.125rem;
        }

        span {
            font-size: 0.875rem;

            @include breakpoint-up('sm') {
                font-size: 1.125rem;
            }
        }

        div {
            right: 10px !important;
            width: 20px !important;

            b {
                background-image: url(/images/ui-select_handle.svg) !important;
                background-position: center;
                background-size: 100% auto;
            }
        }
    }

    .chosen-results li.disabled-result {
        color: #444 !important;
    }
}
