//
// Container widths and default padding
//
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Establish 16px base font size
html {
  font-size: 100%;
  scroll-behavior: smooth;
}

// Native font stack
body {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  @include ffMalden('light');
}

a {
  color: color('ui-purple');
}

// Copy
p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;

  * + & {
    margin-top: 1.5rem;
  }
  &.standfirst {
    font-size: 1.5rem;
    line-height: 2rem;
    @include ffMalden('bold');
  }
}

em {
  @include ffMalden('light', 'italic');
}

strong {
  @include ffMalden('bold');
}

// Headings
h1 {
  @include ffNimbusSans();
  font-size: 2rem;
  line-height: 2rem;
  margin: 0;

  @include breakpoint-up('sm') {
    font-size: 2.5rem;
    line-height: 4.5rem;
  }

  * + & {
    margin-top: 1.5rem;
  }
}

h2 {
  @include ffNimbusSans();
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0;

  @include breakpoint-up('sm') {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  * + & {
    margin-top: 1.5rem;
  }
}

h3 {
  @include ffNimbusSans();
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;

  * + & {
    margin-top: 1.5rem;
  }
}

h4 {
  @include ffMalden('bold');
  margin: 0;

  * + & {
    margin-top: 1.5rem;
  }
}

// Lists

ul {
  padding: 0;
  margin: 0;

  * + & {
    margin-top: 1.5rem;
  }
}

ol {
  padding: 0;
  margin: 0;
  counter-reset: listCounter;

  * + & {
    margin-top: 1.5rem;
  }

  > li {
    list-style-type: none;
		margin: 0;
    counter-increment: listCounter;
    position: relative;
    padding: 0 0 0 2.25rem;

    @include breakpoint-up('sm') {
      padding: 0 0 0 2.875rem;
    }

		&::before {
      @include ffMalden('bold');
      display: block;
      content: counter(listCounter, decimal-leading-zero) "." ;
      position: absolute;
      top: 0;
      left: 0;
		}
  }
}

.middle-east-north-africa{
  ol {
    > li {
      padding: 0 2.25rem 0 0;
  
      @include breakpoint-up('sm') {
        padding: 0 2.875rem 0 0 ;
      }
  
      &::before {
        content: counter(listCounter, decimal-leading-zero) ".";
        left: auto;
        right: 0;
      }
    }
  }
}

// Quotes

blockquote {
  * + & {
    margin-top: 1.5rem;
  }
}

p{
    &.small{
        margin-top: 0;
        font-size: 0.9em;
    }
}
