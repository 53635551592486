.errors {
    .error-header {
        background-color: color('ui-purple');
        background-image: url('../../img/texture-bokeh.png');
        background-size: cover;
        background-position: center center;
        padding: 4.5rem 1.25rem;

        @include breakpoint-up('sm') {
            padding: 3.5rem 14.5rem;
        }

        h1 {
            font-size: 7rem;
            padding-top: 3rem;
            margin-top: 6.5rem;
        }
    }

    .error-content {
        padding: 3.5rem 1.25rem;

        @include breakpoint-up('sm') {
            padding: 3.5rem 14.5rem;
        }

        p {
            font-size: 2rem;
            margin-bottom: 45px;
        }

        a {
            background-color: color('ui-white');
            color: color('ui-black');
            text-decoration: none;
            padding: 1rem 1.75rem;
            border: 1px solid color('ui-white');
            border-radius: 1.75rem;
            @include ffMalden('bold');
            font-size: 0.75rem;
            line-height: 1.125rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;

            @include breakpoint-up('sm') {
                padding: 1.125rem 1.75rem;
            }

            &:focus,
            &:hover {
                background-color: color('ui-black');
                border-color: color('ui-white');
                color: color('ui-white');
                text-decoration: none;
            }

            &:disabled {
                opacity: 0.5;
            }
        }
    }
}
