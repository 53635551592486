.reward-header {
	background-color: color('ui-purple');
	background-image: url('../../img/texture-bokeh.png');
	background-size: cover;
	background-position: center center;
	padding: rem(20) rem(20) rem(16);

    @include breakpoint-up('sm') {
		padding-bottom: rem(30);
    }

    .reward-header-inner{
        @include breakpoint-up('sm') {
            padding: 0rem 0rem 0rem;
        }

        width: 100%;
        max-width: rem(490);
        margin: 0 auto;

        h1{
            max-width: rem(490);
        	margin-top: rem(40);
            font-size: rem(24);
            line-height: rem(30);

			@include breakpoint-up('sm') {
                margin-top: 0;
	        	margin-top: rem(116);
                font-size: rem(32);
                line-height: rem(40);
			}
        }
    }
}

.reward-content{
    padding: rem(30) rem(20) rem(0);

    width: 100%;
    max-width: rem(490);
    margin: 0 auto;

    @include breakpoint-up('sm') {
        padding: rem(30) rem(0) rem(0);
    }

    p{
        strong {
            @include ffMalden('bold');
            font-size: rem(16);
            line-height: rem(24);

            @include breakpoint-up('sm') {
                font-size: rem(24);
                line-height: rem(32);
			}
        }
    }
}

.reward-actions {
    padding: rem(32) rem(20) rem(20);

    @include breakpoint-up('sm') {
	    padding: rem(40) rem(20) rem(20);
    }

	&-inner {
		display: flex;
		flex-direction: column;

		@include breakpoint-up('xs') {
			padding: 0 0 4rem 0;
			max-width: rem(490);
	    	margin: 0 auto;
		}
        @include breakpoint-up('sm') {
            flex-direction: row;
            justify-content: normal;
        }


        > button,
        > .cta {
            min-width: 0;
            max-width: fit-content;
        }
    }
}

.reward-button-go-back {
    display: none;
    padding: 0px;

    text-decoration: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

	&::before {
		display: inline-block;
		content: '';
		width: 7px;
		height: 0.875rem;
		background-image: url('/images/icon-toggle-right-white.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: auto 0.875rem;
		margin-right: 0.875rem;
		transform: rotate(180deg);
	}

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&.is-active {
		display: flex;
		align-items: center;
	}

    @include breakpoint-up('sm') {
        display: flex;
        z-index: 1;
    }
}

.reward_actions {
    &-header {
        padding-top: rem(28);
        display: flex;
        padding-left: 0;
        @include breakpoint-up('sm') {
            display: none;
        }

        .reward-button-go-back {
            display: flex;
            z-index: 1;
            @include breakpoint-up('sm') {
                display: none;
            }
        }
    }

    &-footer{
        position: absolute;
        bottom: rem(40);
        left: rem(40);
    }
}

.reward-cta {
    margin-bottom: 1.25rem;
    @include breakpoint-up('sm') {
        margin-bottom: 0;
        margin-right: rem(40);
    }
}

.reward{
    .support-cta {
        background-color: #000000;
        border-color: #ffffff;
        color: #ffffff;

        &:hover {
            background-color: #ffffff;
            border-color: #000000;
            color: #000000;
        }
    }
    .site-footer{
        display: none;
    }

    .partner-logo{
        .imported-logo{
            max-width: rem(105);
            max-height: rem(45);

            @include breakpoint-up('sm') {
                max-width: rem(141);
                max-height: rem(60);
            }
        }
    }
}

.middle-east-north-africa{
    .reward-cta {
        @include breakpoint-up('sm') {
            margin-right: 0;
        }
    }
}
