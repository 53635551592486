body {
    &.el-GR {
        h1:not(.hp-title), h2 {
            font-weight: bolder;
        }

        a:not(.icon-link, .icon-link-lang), button {
            font-weight: bold;
        }

        span {
            font-weight: normal;
        }

        h1:not(.hp-title),
        h2,
        a:not(.icon-link, .icon-link-lang),
        button:not(.vjs-play-control),
        span,
        label,
        p,
        input,
        textarea,
        select,
        option,
        li,
        strong,
        .radio-with-label label {
            font-family: 'Open Sans', sans-serif;
        }

        .vjs-control-bar {
            font-family: "Overlook Sans W05 Light", sans-serif;
        }
    }

    &.es-ES {
        &.advice {
            .advice-content-inner h2 {
                font-size: 1rem;
                line-height: 1.75rem;

                @include breakpoint-up('xxs') {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }

                @include breakpoint-up('xs') {
                    font-size: 2.5rem;
                    line-height: 3.125rem;
                }

            }
        }
    }
}


